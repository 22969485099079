import React from 'react';
import { Button, Stepper, StepLabel, Paper, Step, CssBaseline, makeStyles, Typography, CircularProgress } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from "@material-ui/icons";
import { saveShipping, savePayment, removeAllCart } from "../../redux";
import { computeCheckoutItems, getCoupon as verifyCoupon, checkout as checkoutFunc, givePoint } from './func'
import { AppState, CheckoutSummary } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddressForm from './address';
import PaymentForm from './payment';
import { generateOrderNumber } from "../../util";
import Review from './review';
import config from "../../config";
import { Colors } from "../../colors";
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(15),
        paddingBottom: theme.spacing(10)
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: 20
        },
    },
    paper: {
        marginTop: theme.spacing(5),
        background: 'inherit',
        // marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(5),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
        background: 'inherit',
        "& $active": {
            color: "red"
        },
    },
    stepperRoot: {
        '&$active': {
            color: 'green',
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));
const steps = ['Shipping', 'Payment', 'Order'];
function getStepContent(
    step: number,
    shippingFee: number,
    couponAmount: number,
    couponError: string | null,
    addressInput: (address: string) => any,
    cityInput: (city: string) => any,
    paymentInput: (payment: string) => any,
    shippingInput: (ship: string) => any,
    couponInput: (v: string) => any,
    noteInput: (v: string) => any,
    getCoupon: () => any,
) {
    switch (step) {
        case 0:
            return <AddressForm addressInput={addressInput} cityInput={cityInput} />;
        case 1:
            return <PaymentForm paymentInput={paymentInput} shippingInput={shippingInput} />;
        case 2:
            return <Review
                shippingFee={shippingFee}
                couponInput={couponInput}
                noteInput={noteInput}
                getCoupon={getCoupon}
                error={couponError}
                couponAmount={couponAmount}
            />;
        default:
            throw new Error('Unknown step');
    }
}
interface Props {
    loading: boolean;
    address: string | null;
    couponAmount: number;
    couponCode: string | null
    couponError: string | null;
    notes: string | null;
    orderNo: string | null
    city: string | null;
    shippingFee: string;
    paymentMethod: string;
    activeStep: number;
    changedAddress: boolean;
    changedCity: boolean;
    changedPayment: boolean;
}
const defaultState: Props = {
    address: null, city: null, shippingFee: '0', paymentMethod: 'cash', activeStep: 0,
    couponAmount: 0, couponError: null, notes: null, couponCode: null,
    changedAddress: false,
    changedCity: false,
    changedPayment: false,
    loading: false,
    orderNo: null,
}
export default function C() {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    const { CartState, AuthState, ShippingState, MerchantState } = useSelector(state => state) as AppState
    const [state, setState] = React.useState({
        ...defaultState, address: CartState.shipping?.address, city: CartState.shipping?.city,
        shippingFee: ShippingState.data.length > 0 ? ShippingState.data[0].amount.toString() : '0'
    });
    const addressInput = (address: string) => {
        setState({ ...state, address, changedAddress: true })
    }
    const cityInput = (city: string) => {
        setState({ ...state, city, changedCity: true })
    }
    const paymentInput = (paymentMethod: string) => {
        setState({ ...state, paymentMethod, changedPayment: true })
    }
    const shippingInput = (shippingFee: string) => {
        setState({ ...state, shippingFee })
    }
    const couponInput = (couponCode: string) => {
        setState({ ...state, couponCode })
    }
    const noteInput = (notes: string) => {
        setState({ ...state, notes })
    }
    const getCoupon = async () => {
        if (state.couponCode === null) {
            setState({ ...state, couponError: 'Enter valid coupon code.' })
        } else {
            const result = computeCheckoutItems(CartState.items)
            const reqAmount = result.totalNetAmount + parseInt(state.shippingFee, 10)
            const res = await verifyCoupon({
                merchantId: config.merchantId,
                couponId: state.couponCode.toLocaleUpperCase(),
                amount: reqAmount,
                memberIdOrPhoneNo: AuthState.status === 'loggedIn' ? AuthState.user.phone : '',
            })
            if (res.success) {
                let cAmount = 0
                if (res.unit === 'percentage') {
                    cAmount = cAmount + reqAmount * (res.value / 100)
                } else {
                    cAmount = cAmount + (res.value || 0)
                }
                setState({ ...state, couponAmount: cAmount, couponError: null })
            } else {
                setState({
                    ...state, couponError: res.message || 'Invalid Coupon', couponAmount: 0
                })
            }
        }

    }
    const checkoutHandler = async () => {
        setState({ ...state, loading: true })
        const result = computeCheckoutItems(CartState.items)
        const orderNo = generateOrderNumber()
        const _totalAmount = result.totalNetAmount + parseInt(state.shippingFee, 10) - state.couponAmount
        const res = await checkoutFunc({
            merchantId: config.merchantId,
            customer: {
                address: [state.address || '', state.city || ''].join(','),
                name: AuthState.status === 'loggedIn' ? AuthState.user.name : '',
                phone: AuthState.status === 'loggedIn' ? AuthState.user.phone : '',
                payment: state.paymentMethod || ''
            },
            detail: result.items,
            //  totalNetAmount + parseInt(shipAmount, 10) - couponAmount,
            other: {
                ...result,
                totalNetAmount: result.totalAmount,
                totalAmount: _totalAmount,
                earnPoints: MerchantState.data.posPointSystem !== 'separate' ? givePoint(MerchantState.data, _totalAmount) : result.totalEarnPoints,
                orderType: 'Web',
                notes: state.notes || '',
                couponId: state.couponCode || '',
                couponAmount: state.couponAmount,
                totalDiscountAmount: state.couponAmount,
                paymentType: state.paymentMethod || "Cash",
                currency: 'MMK',
                discount: {},
                orderStatus: 'Pending',
                orderId: orderNo,
                shippingAmount: parseInt(state.shippingFee, 10),
                totalInclusiveTaxAmount: 0,
            } as CheckoutSummary
        })
        if (res.success) {
            dispatch(removeAllCart())
            setState({
                ...defaultState,
                address: '',
                city: '',
                orderNo,
                activeStep: state.activeStep + 1,
                loading: false,
            })
        }
    }
    const handleNext = async () => {
        switch (state.activeStep) {
            case 0:
                if (state.changedAddress && state.city) {
                    dispatch(saveShipping({ address: state.address || '', city: state.city }))
                }
                setState({ ...state, activeStep: state.activeStep + 1 })
                break
            case 1:
                setState({ ...state, activeStep: state.activeStep + 1 })
                dispatch(savePayment(state.paymentMethod))
                break
            case 2:
                await checkoutHandler()
                break
            default:
                break
        }
    };
    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.layout}>
                <Paper className={classes.paper} elevation={0}>
                    <Typography component="h1" variant="h5" align="center">
                        Checkout
                    </Typography>
                    <Stepper activeStep={state.activeStep} className={classes.stepper}
                        classes={{ root: classes.stepperRoot }}
                    >
                        {steps.map((label) => (
                            <Step key={label} >
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {state.activeStep === steps.length ? (
                            <React.Fragment>
                                <Typography variant="h5" gutterBottom>
                                    Thank you for your order.
                                 </Typography>
                                <Typography variant="subtitle1">
                                    Your order number is {state.orderNo}. We have sent sms your order confirmation, and will
                                    send you an update when your order has shipped.
                                </Typography>
                                <Typography>
                                    <Button
                                        size='small'
                                        style={{ color: Colors.primary }}
                                        onClick={() => history.push('/')}
                                        fullWidth>
                                        Go Home
                                    </Button>
                                </Typography>
                            </React.Fragment>
                        ) :
                            (
                                <React.Fragment>
                                    {getStepContent(
                                        state.activeStep,
                                        parseInt(state.shippingFee, 10),
                                        state.couponAmount,
                                        state.couponError,
                                        addressInput,
                                        cityInput,
                                        paymentInput,
                                        shippingInput,
                                        couponInput,
                                        noteInput,
                                        getCoupon
                                    )}
                                    <div className={classes.buttons}>
                                        <Button
                                            onClick={handleNext}
                                            className={classes.button}
                                            size='small'
                                            variant='contained'
                                            //  color='primary'
                                            style={{
                                                backgroundColor: Colors.primary
                                            }}
                                            startIcon={
                                                state.loading ?
                                                    <CircularProgress size={20} style={{ color: 'blanchedalmond' }} /> :
                                                    <ArrowForwardIosIcon />
                                            }
                                        >
                                            {state.activeStep === 2 ? 'Checkout' : 'Next'}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                    </React.Fragment>
                </Paper>
            </main>
        </div >
    );
}